import axios from "axios";
import Auth from '@/helper/auth.js'

export default {
    jJabatan: async (token) => {
        // Ref Jenis Jabatan
        let url = process.env.VUE_APP_API + 'ref/jenisjabatan'

        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            return response.data.mapData.referensi.jabatanJenis
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout()
            } else {
                return []
            }
        }
    },

    eselon: async (token) => {
        // Ref Eselon
        let url = process.env.VUE_APP_API + 'ref/eselon'

        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            return response.data.mapData.referensi.eselon
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout()
            } else {
                return []
            }
        }
    },

    pelaksana: async (token) => {
        // Ref Pelaksana
        let url = process.env.VUE_APP_API + 'ref/pelaksana'

        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            return response.data.mapData.referensi.jabatanFungsionalUmum
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout()
            } else {
                return []
            }
        }
    },

    fungsional: async (token) => {
        // Ref Fungsional
        let url = process.env.VUE_APP_API + 'ref/fungsional'

        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            return response.data.mapData.referensi.jabatanFungsional
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout()
            } else {
                return []
            }
        }
    },

    formasi: async (token) => {
        let url = process.env.VUE_APP_API + 'ref/formasijabatan'

        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            return response.data.mapData.referensi.formasiJabatan
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout()
            } else {
                return []
            }
        }
    },

    new_jj: async (token) => {
        let url = process.env.VUE_APP_API + 'ref/jabatanstatus'

        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            return response.data.mapData
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout()
            } else {
                return []
            }
        }
    },

    new_pelaksana: async (token) => {
        let url = process.env.VUE_APP_API + 'ref/pelaksana'

        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            return response.data.mapData.referensi.jabatanPelaksana
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout()
            } else {
                return []
            }
        }
    },

    pimpinantinggi: async (token) => {
        let url = process.env.VUE_APP_API + 'ref/pimpinantinggi'

        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            return response.data.mapData.referensi.pimpinantinggi
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout()
            } else {
                return []
            }
        }
    },

    administrator: async (token) => {
        let url = process.env.VUE_APP_API + 'ref/administrator'

        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            return response.data.mapData.referensi.administrator
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout()
            } else {
                return []
            }
        }
    },

    pengawas: async (token) => {
        let url = process.env.VUE_APP_API + 'ref/pengawas'

        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            return response.data.mapData.referensi.pengawas
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout()
            } else {
                return []
            }
        }
    },

    status: () => {
        const refstatus = [{
                id: "active",
                nama: "Active",
            },
            {
                id: "inactive",
                nama: "Inactive",
            },
        ]

        return refstatus
    },

    shift: () => {
        const refshift = [{
                id: "ya",
                nama: "YA",
            },
            {
                id: "tidak",
                nama: "TIDAK",
            },
        ]

        return refshift
    },

    workplace: () => {
        const refwp = [{
                id: "WFO",
                nama: "WFO (Work From Office)",
            },
            {
                id: "WFH",
                nama: "WFH (Work From Home)",
            },
            {
                id: "WFA",
                nama: "WFA (Work From Anywhere)",
            },
        ]

        return refwp
    },

    kelasjabatan: () => {
        const refkelasjab = [{
                id: "kelas 1",
                nama: "Kelas 1",
            },
            {
                id: "kelas 2",
                nama: "Kelas 2",
            },
            {
                id: "kelas 3",
                nama: "Kelas 3",
            },
            {
                id: "kelas 4",
                nama: "Kelas 4",
            },
            {
                id: "kelas 5",
                nama: "Kelas 5",
            },
            {
                id: "kelas 6",
                nama: "Kelas 6",
            },
            {
                id: "kelas 7",
                nama: "Kelas 7",
            },
            {
                id: "kelas 8",
                nama: "Kelas 8",
            },
            {
                id: "kelas 9",
                nama: "Kelas 9",
            },
            {
                id: "kelas 10",
                nama: "Kelas 10",
            },
            {
                id: "kelas 11",
                nama: "Kelas 11",
            },
            {
                id: "kelas 12",
                nama: "Kelas 12",
            },
        ]

        return refkelasjab
    },
}